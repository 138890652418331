import { api } from '@/lib';
import { useQuery } from 'react-query';

async function getGetNotes({ patientId }: { patientId: string }) {
  const { data } = await api.get<any>(
    `/Anotacao?property=usuarioPacienteId&value=${patientId}&include=UsuarioProfissional.Pessoa`,
  );

  return data.data;
}

export function useGetNotesQuery(patientId: string) {
  const query = useQuery(['getNotes', patientId], () =>
    getGetNotes({ patientId }),
  );

  return query;
}
