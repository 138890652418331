import { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Flex,
  Image,
  BoxProps,
  Text,
  Icon,
} from '@chakra-ui/react';
import logoDM from '@/assets/logo-dm.png';
import { IoArrowBack } from 'react-icons/io5';

type DrawerIaChatProps = BoxProps & {
  onMessageCallback?: (message: string) => void;
  isFluctuant?: boolean;
};

export function DrawerIaChat({
  onMessageCallback,
  isFluctuant,
  ...rest
}: DrawerIaChatProps) {
  const [step, setStep] = useState(0);
  const [selectAssistant, setSelectAssistant] = useState<any>(null);

  return (
    <Box minW="2xl" zIndex={100} {...rest}>
      <Accordion position="relative" allowToggle>
        <AccordionItem bg="gray.100">
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              <Flex gap={2}>
                <Image src={logoDM} w={23} borderRadius="30%" />
                D. I.
              </Flex>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel
            position={isFluctuant ? 'absolute' : 'inherit'}
            top={9}
            right={-4}
            left={-4}
            pb={4}
          >
            {step === 0 ? (
              <Box bg="white">
                <Text textColor="gray.500" fontSize="md" align="center" p="2">
                  Selecione um chat e inicie a conversa.
                </Text>
              </Box>
            ) : (
              <Box bg="gray.100">
                <Flex alignItems="center">
                  <Icon
                    as={IoArrowBack}
                    fontSize="20px"
                    color="#8A8A8A"
                    right="0"
                    justifyContent="flex-end"
                    margin="2"
                    cursor="pointer"
                    onClick={() => {
                      setSelectAssistant(null);
                      setStep(0);
                    }}
                  />
                  <Text
                    fontSize="lg"
                    fontWeight="medium"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {selectAssistant?.nome || 'D. I.'}
                  </Text>
                </Flex>
              </Box>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
}
