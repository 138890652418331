import { useEffect, useState } from 'react';
import { Box, Center, Flex, Image, Icon, Text } from '@chakra-ui/react';
import { Header, VideoCall } from '@/components';
import { useHeaderInfoQuery } from '@/hooks';
import { getStatusConsultation } from '@/hooks/queries/useEndConsultation';
import { ConsultationClosed } from '@/components/ConsultationClosed';
import HistoryCard from '@/components/HistoryCard';
import AnamneseCard from '@/components/AnamneseCard';
import { ExpandIcon, ArrowDown, ArrowUp } from '@/assets/svgs';
import HistoryHorizCard from '@/components/HistoryCard/HistoryHorizCard';

export function Main() {
  const { data, isLoading } = useHeaderInfoQuery();
  const [expand, setExpand] = useState(0);
  const [expandGraphs, setExpandGraphs] = useState(false);
  const [dateValid, setDateValid] = useState(true);
  const [moveItem, setMove] = useState(false);
  const [customStyle, setCustomStyle] = useState<any>({
    position: 'fixed',
    bottom: '20px',
    zIndex: 2,
    right: '16px',
  });
  const [message, setMessage] = useState('');
  const [desc, setDesc] = useState('');
  const status = getStatusConsultation();
  const date = new Date(data?.schedule.date ?? '').toLocaleDateString('pt-BR');

  const getValidDateConsult = async () => {
    if (data?.schedule.date) {
      const dateConsult = Date.parse(
        `${data.schedule.date.split('T')[0]}T${data.schedule.hour}`,
      );

      if (dateConsult > new Date().setMinutes(new Date().getMinutes() + 60)) {
        setDateValid(false);
        setMessage(
          `A consulta está marcada para o dia ${date} às ${data.schedule.hour.slice(
            0,
            5,
          )}h.`,
        );
        setDesc(
          'Você só poderá acessar essa sala 1 hora antes do horário de início da consulta.',
        );
      } else if (
        dateConsult < new Date().setMinutes(new Date().getMinutes() - 60)
      ) {
        setDateValid(false);
        setMessage(
          `A consulta estava marcada para o dia ${date} às ${data.schedule.hour.slice(
            0,
            5,
          )}h.`,
        );
        setDesc(
          'Você só pode acessar a sala em até 1 hora após o horário de início da consulta.',
        );
      }
    }
  };

  useEffect(() => {
    getValidDateConsult();
  }, [data]);

  return data ? (
    data?.consultaStatus === 6 ? (
      <ConsultationClosed
        title="Consulta encerrada!"
        text="Essa consulta já foi finalizada. Não é possível acessar essa sala."
      />
    ) : !dateValid ? (
      <ConsultationClosed
        title="Sala indisponível"
        text={message}
        desc={desc}
      />
    ) : (
      <Flex
        as="main"
        w="100%"
        direction="column"
        backgroundColor="#f7f7f7"
        style={{ maxHeight: '100vh', height: '100%', overflow: 'hidden' }}
        onMouseMove={e => {
          if (moveItem) {
            setCustomStyle({
              position: 'fixed',
              left: e.clientX - 100,
              zIndex: 2,
              top: e.clientY - 200,
            });
          }
        }}
      >
        <Header />

        <Flex
          as="section"
          px="28px"
          pl="72px"
          w="100%"
          minH={[
            'calc(100vh - 186px)',
            'calc(100vh - 116px)',
            null,
            'calc(100vh - 158px)',
          ]}
          direction={{ base: 'column', md: 'row' }}
          gap="20px"
        >
          {data?.schedule?.type ? (
            <Box
              w={
                expand === 1 || expand === 2
                  ? '100%'
                  : expand === 0
                  ? '50%'
                  : '0'
              }
              p={4}
              color="white"
            >
              <Box
                onMouseDown={() => {
                  if (expand !== 0 && expand !== 1) {
                    setMove(true);
                  }
                }}
                onMouseUp={e => {
                  setMove(false);
                }}
                id="frameCall"
                style={
                  expand === 0 || expand === 1
                    ? {
                        width: '100%',
                        height: `${expand === 1 ? '100%' : '60%'}`,
                        padding: 2,
                        color: 'white',
                      }
                    : { ...customStyle }
                }
              >
                <VideoCall expand={expand} setExpand={setExpand} />
              </Box>
              {expand !== 0 && expand !== 1 && expand !== 2 ? null : expand ===
                  0 || expand === 2 ? (
                <Box
                  w="100%"
                  h={expand === 2 ? '100%' : '40%'}
                  p={2}
                  color="white"
                  paddingBottom="10"
                  backgroundColor="#fff"
                  borderRadius="15"
                >
                  {expand === 0 ? (
                    <HistoryHorizCard
                      paciente={data?.patient}
                      expand={expand}
                      setExpand={setExpand}
                    />
                  ) : (
                    <HistoryCard
                      paciente={data?.patient}
                      expand={expand}
                      setExpand={setExpand}
                    />
                  )}
                </Box>
              ) : null}
            </Box>
          ) : (
            <Box
              w="100%"
              p={4}
              color="white"
              paddingBottom="10"
              backgroundColor="#fff"
              overflowY="auto"
              borderRadius="15"
              sx={{
                '&::-webkit-scrollbar': { width: '6px' },
                '&::-webkit-scrollbar-track': { borderRadius: '8px' },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '15',
                  bg: '#bfbfbf',
                },
              }}
            >
              <HistoryCard
                paciente={data?.patient}
                expand={expand}
                setExpand={setExpand}
              />
            </Box>
          )}

          {expand !== 0 && expand !== 3 && expand !== 4 ? null : (
            <Box w="100%" p={4} color="white">
              {expand === 0 || expand === 3 ? (
                <Flex
                  w="100%"
                  h={expandGraphs ? (expand === 3 ? '100%' : '50%') : '10%'}
                  p={2}
                  justifyContent={expandGraphs ? 'normal' : 'center'}
                  alignItems={expandGraphs ? 'normal' : 'center'}
                >
                  <Box bg="white" w="100%" border="1px solid #0007">
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="flex-end"
                      padding="5"
                    >
                      {expandGraphs ? (
                        <>
                          <Image
                            position="absolute"
                            width="20px"
                            height="auto"
                            fontSize="30px"
                            marginRight="10"
                            cursor="pointer"
                            onClick={() => {
                              if (expand === 3) {
                                setExpand(0);
                              } else {
                                setExpand(3);
                              }
                            }}
                            src={ExpandIcon}
                          />
                          <Image
                            position="absolute"
                            width="20px"
                            height="auto"
                            fontSize="30px"
                            marginRight="0"
                            cursor="pointer"
                            onClick={() => {
                              setExpandGraphs(!expandGraphs);
                            }}
                            src={ArrowUp}
                          />
                        </>
                      ) : (
                        <Image
                          position="absolute"
                          width="20px"
                          height="auto"
                          fontSize="30px"
                          marginRight="0"
                          cursor="pointer"
                          onClick={() => {
                            setExpandGraphs(!expandGraphs);
                          }}
                          src={ArrowDown}
                        />
                      )}
                    </Box>
                    <Center w="100%" h="100%" color="#000">
                      <Text fontSize="lg">GRÁFICOS POWER BI</Text>
                    </Center>
                  </Box>
                </Flex>
              ) : null}

              <Box
                w={expand === 4 || expand === 0 ? '100%' : 0}
                h={expandGraphs ? (expand === 4 ? '100%' : '50%') : '90%'}
                p={2}
                color="white"
                overflowY="auto"
                borderRadius="15"
                sx={{
                  '&::-webkit-scrollbar': { width: '6px' },
                  '&::-webkit-scrollbar-track': { borderRadius: '8px' },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '15',
                    bg: '#bfbfbf',
                  },
                }}
              >
                <AnamneseCard expand={expand} setExpand={setExpand} />
              </Box>
            </Box>
          )}
        </Flex>
      </Flex>
    )
  ) : (
    <ConsultationClosed title="Aguarde!" text="Buscando dados da consulta..." />
  );
}
