import {
  FormControl,
  FormLabel,
  Textarea,
  TextareaProps,
  Icon,
  IconButton,
} from '@chakra-ui/react';
import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useState,
} from 'react';
import { FieldError } from 'react-hook-form';
import { BiVolumeFull, BiVolumeMute } from 'react-icons/bi';
import SpeechToText from '@/pages/SpeechToText';

interface TextAreaInputProps extends TextareaProps {
  label: string;
  error?: FieldError;
  value: string;
  onChange: any;
}

const TextAreaBase: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  TextAreaInputProps
> = ({ name, isRequired, value, onChange, label, error, ...rest }, ref) => {
  const [listen, setListen] = useState(false);
  const [text, setText] = useState('');

  const ButtonsListen = () =>
    listen ? (
      <IconButton
        onClick={() => setListen(!listen)}
        variant="ghost"
        icon={<Icon as={BiVolumeMute} fontSize="18px" />}
        aria-label="Excluir todos os valores"
        colorScheme="whiteAlpha"
        color="text.darkened"
        position="absolute"
        right="0"
        bottom="0"
        zIndex="999"
      />
    ) : (
      <IconButton
        onClick={() => setListen(!listen)}
        variant="ghost"
        icon={<Icon as={BiVolumeFull} fontSize="18px" />}
        aria-label="Excluir todos os valores"
        colorScheme="whiteAlpha"
        color="text.darkened"
        position="absolute"
        right="0"
        bottom="0"
        zIndex="999"
      />
    );

  useEffect(() => {
    onChange(value.concat(` ${text}`));
  }, [text]);

  return (
    <FormControl isRequired={isRequired} isInvalid={!!error}>
      <SpeechToText setText={setText} setListen={setListen} listen={listen} />
      <FormLabel
        htmlFor={name}
        color="text.default"
        fontSize="15px"
        fontWeight="500"
      >
        {label}
      </FormLabel>
      <Textarea
        {...rest}
        id={name}
        name={name}
        ref={ref}
        value={value}
        onChange={(e: any) => onChange(e.target.value)}
        resize="none"
        pl="16px"
        h="100px"
        borderColor="#bfbfbf"
        background="white"
        color="text.default"
        _hover={{
          borderColor: 'brand-green.default',
        }}
      />
      <ButtonsListen />
    </FormControl>
  );
};

export const TextAreaInputVoice = forwardRef(TextAreaBase);
