import { useGetConsultas } from '@/hooks/mutations/getConsultas';
import { MONTHS } from '@/types/constants';
import {
  Box,
  VStack,
  Flex,
  Grid,
  GridItem,
  Icon,
  Text,
  InputLeftAddon,
  InputGroup,
  Image,
  Input,
  CircularProgress,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  IoAddCircleOutline,
  IoArrowForward,
  IoEllipseOutline,
  IoRemoveOutline,
} from 'react-icons/io5';
import iaImg from '@/assets/svgs/ia_icon.svg';
import { ExpandIcon } from '@/assets/svgs';
import InputAnamneseCard from '../AnamneseCard/InputAnamneseCard';
import RadioCard from './RadioCard';

type HistoryProps = {
  paciente?: any;
  expand?: number;
  setExpand?: any;
};

export default function HistoryCard({
  paciente,
  expand,
  setExpand,
}: HistoryProps) {
  const [consultas, setConsultas] = useState<any[]>();
  const [consultasForm, setConsultasForm] = useState<any[]>();
  const [selectConsulta, setSelectConsulta] = useState(null);
  const { mutate: getConsults, isLoading } = useGetConsultas();

  const getConsultas = async () => {
    getConsults(paciente?.id, {
      onSuccess: (response: any) => {
        setConsultas(response);
      },
    });
  };

  useEffect(() => {
    getConsultas();
  }, [paciente]);

  const getDateMonth = (num: number) => {
    const name = MONTHS.filter((m: any) => m.numeric === num)[0];

    return name.numeric;
  };

  const getCategories = () => {
    if (consultas) {
      const c = consultas?.reduce((acc, item, i) => {
        return {
          ...acc,
          [getDateMonth(new Date(item.horasAgenda.agenda.data).getMonth())]: [
            ...(acc[
              getDateMonth(new Date(item.horasAgenda.agenda.data).getMonth())
            ] ?? []),
            item,
          ],
        };
      }, {});

      setConsultasForm(c);
    }
  };

  useEffect(() => {
    getCategories();
  }, [consultas]);

  const backConsulta = () => {
    setSelectConsulta(null);
  };

  return (
    <>
      <Text
        backgroundColor="#fff"
        fontSize="lg"
        w="100%"
        color="#0005"
        fontFamily="Rubik"
        padding="5"
        borderTopRadius="15"
        display="flex"
        alignItems="center"
      >
        HISTÓRICO
        <Box width="100%" display="flex" justifyContent="flex-end">
          <Image
            width="20px"
            fontSize="30px"
            marginRight="0"
            cursor="pointer"
            onClick={() => {
              if (expand === 2) {
                setExpand(0);
              } else {
                setExpand(2);
              }
            }}
            src={ExpandIcon}
          />
        </Box>
      </Text>

      <Flex
        backgroundColor="#fff"
        direction="column"
        w="100%"
        h="100%"
        borderBottomRadius="15"
      >
        {isLoading ? (
          <CircularProgress
            value={30}
            size="120px"
            isIndeterminate
            alignSelf="center"
            justifySelf="center"
          />
        ) : !selectConsulta ? (
          <Grid
            width="100%"
            paddingBottom="10"
            marginBottom="10"
            templateColumns="repeat(12, 1fr)"
            overflowY="auto"
            sx={{
              '&::-webkit-scrollbar': { width: '6px' },
              '&::-webkit-scrollbar-track': { borderRadius: '8px' },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '15',
                bg: '#bfbfbf',
              },
            }}
          >
            {MONTHS.map((m: any) => {
              const { name } = m;
              return (
                <>
                  <GridItem
                    display="flex"
                    colSpan={12}
                    style={{ height: '100%' }}
                  >
                    {consultasForm && consultasForm[m.numeric] && (
                      <Box
                        width="100%"
                        flexDirection="column"
                        //  borderRight="1px dotted #E4E4E4"
                      >
                        <Box borderBottom="1px solid #E4E4E4">
                          <Text
                            color="#0005"
                            fontSize="lg"
                            fontWeight="medium"
                            paddingTop="10"
                            paddingLeft="15"
                            fontFamily="Rubik"
                          >
                            {name}
                          </Text>
                        </Box>
                        <Box width="max-content" marginLeft="20">
                          {consultasForm &&
                            consultasForm[m.numeric]?.map((c: any) => {
                              const date = new Date(
                                c.horasAgenda.agenda.data ?? '',
                              ).toLocaleDateString('pt-BR');
                              return (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  paddingTop="10"
                                  cursor="pointer"
                                  onClick={() => setSelectConsulta(c)}
                                >
                                  <VStack spacing="1" ml="4" align="center">
                                    <Flex>
                                      <Box left="90%" backgroundColor="#fff">
                                        <Icon
                                          as={IoEllipseOutline}
                                          color="#D6D6D6"
                                          fontSize="30px"
                                        />
                                        <Icon
                                          as={IoRemoveOutline}
                                          color="#D6D6D6"
                                          fontSize="30px"
                                        />
                                      </Box>
                                      <Box>
                                        <Text
                                          color="#000"
                                          fontSize="lg"
                                          fontWeight="large"
                                          fontFamily="Rubik"
                                        >
                                          {c.especialidade.length > 0
                                            ? c.especialidade[0].descricao
                                            : c.conselho[0].descricao}
                                        </Text>
                                        <Text
                                          color="#0005"
                                          fontSize="xs"
                                          fontWeight="normal"
                                          fontFamily="Rubik"
                                        >
                                          {date}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </VStack>
                                </Box>
                              );
                            })}
                        </Box>
                      </Box>
                    )}
                  </GridItem>

                  <GridItem
                    display="flex"
                    colSpan={6}
                    style={{ height: '100%' }}
                  />
                </>
              );
            })}
          </Grid>
        ) : (
          <Box p="20px" h="100%">
            <Flex alignItems="center" mb="19">
              <Image width="55px" height="auto" src={iaImg} alt="Logo da IA" />
              <InputGroup ml="30">
                <InputLeftAddon
                  backgroundColor="#fff"
                  color="#60C2B0"
                  fontFamily="Rubik"
                  borderRadius="40%"
                >
                  I.A.
                </InputLeftAddon>
                <Input
                  color="#000"
                  type="text"
                  placeholder="Faça uma pergunta"
                  onChange={() => {}}
                  fontFamily="Rubik"
                  borderRadius="20"
                />
              </InputGroup>
            </Flex>
            <RadioCard dados={selectConsulta} onPress={backConsulta} />
          </Box>
        )}
      </Flex>
    </>
  );
}
