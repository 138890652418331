import React, { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';

interface SpeechToTextProps {
  setText: any;
  setListen: any;
  listen: boolean;
}

const SpeechToText = ({ setText, listen, setListen }: SpeechToTextProps) => {
  const toast = useToast({
    duration: 4000,
    isClosable: true,
    position: 'top-right',
  });
  const [transcript, setTranscript] = useState('');
  const [timeoutId, setTimeoutId] = useState<any>();
  const [isTimeoutCreated, setIsTimeoutCreated] = useState(false);

  const SpeechRecognition =
    (window as any).SpeechRecognition ||
    (window as any).webkitSpeechRecognition;

  const recognition = new SpeechRecognition();

  recognition.continuous = true;
  recognition.interimResults = false;
  recognition.lang = 'pt-BR';

  recognition.onend = () => {
    console.log('Som não detectado');
  };

  recognition.onresult = (event: {
    resultIndex: any;
    results: { transcript: React.SetStateAction<string> }[][];
  }) => {
    // console.log(
    //   'aqqq',
    //   listen,
    //   event.results,
    //   event.results[event.resultIndex][0].transcript,
    // );
    if (listen) {
      try {
        setTranscript(event.results[event.resultIndex][0].transcript);
      } catch (error) {
        console.error('Erro ao processar resultado:', error);
      }
    }
  };

  recognition.onerror = (error: any) => {
    console.error('Erro no reconhecimento de voz:', error);
    recognition.stop();
    clearTimeout(timeoutId);
    setIsTimeoutCreated(false);
    setListen(false);
  };

  const start = () => {
    recognition.start();
  };

  useEffect(() => {
    setText(transcript);
  }, [transcript]);

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setIsTimeoutCreated(false);
    if (listen) {
      start();
    } else {
      recognition.stop();
    }
    return () => {
      recognition.stop();
    };
  }, [listen]);

  return null;
};

export default SpeechToText;
