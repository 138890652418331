import { ExpandIcon } from '@/assets/svgs';
import { useHeaderInfoQuery } from '@/hooks';
import { Box, Button, Flex, Icon, Image, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { BiExitFullscreen, BiExpand } from 'react-icons/bi';
import { useTimer } from 'react-timer-hook';

export function VideoCall({ expand, setExpand }: any) {
  const { data, isLoading } = useHeaderInfoQuery();
  const expiryTimestamp = new Date('2023-05-02T03:00:00');

  const { seconds, minutes, hours } = useTimer({
    autoStart: true,
    expiryTimestamp,
  });
  return (
    <Flex
      // maxW={['100%', null, null, w]}
      height="100%"
      borderTopRadius="26px"
      bg="white"
      boxShadow="2px 2px 18px -7px rgba(15, 15, 38, 0.15)"
      direction="column"
      cursor="pointer"
    >
      <Flex
        borderRadius="26px"
        align="center"
        justify="center"
        backgroundColor="#414141"
        flexDirection="column"
        height="100%"
        cursor="pointer"
      >
        {!isLoading && (
          <iframe
            style={{
              borderTopLeftRadius: '15px',
              borderTopRightRadius: '15px',
            }}
            height="100%"
            width="100%"
            title="dm"
            src={data?.urlVideo}
            allow="camera; microphone; fullscreen; speaker; display-capture; autoplay"
          />
        )}
        <Image
          width="50px"
          height="auto"
          fontSize="30px"
          marginLeft={5}
          marginRight="2"
          cursor="pointer"
          borderRadius="50%"
          backgroundColor="#fff"
          padding="3"
          margin="3"
          onClick={() => {
            if (expand === 1) {
              setExpand(0);
            } else {
              setExpand(1);
            }
          }}
          src={ExpandIcon}
        />
      </Flex>
    </Flex>
  );
}
