import {
  Avatar,
  Grid,
  GridItem,
  Skeleton,
  Text,
  VStack,
  useBreakpointValue,
  InputLeftAddon,
  InputGroup,
  Image,
  Input,
} from '@chakra-ui/react';
import iaImg from '@/assets/svgs/ia_icon.svg';
import { useHeaderInfoQuery } from '@/hooks';
import { DrawerIaChat } from './DrawerIaChat';

const avatar =
  'https://avatars.dicebear.com/api/micah/usr.svg?backgroundColor=black';

export function ConsultationInfo() {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const { data, isLoading } = useHeaderInfoQuery();

  return (
    <Skeleton isLoaded={!isLoading}>
      <Grid width="100%" templateColumns="repeat(12, 1fr)">
        <GridItem display="flex" colSpan={6}>
          <Avatar
            size="lg"
            src={data?.patient?.avatar || avatar}
            name={data?.patient?.name}
          />

          <VStack spacing="1" ml="4" align="flex-start">
            <Text
              color="#A1A1A1"
              fontSize="xs"
              fontWeight="normal"
              fontFamily="Rubik"
            >
              PACIENTE
            </Text>
            <Text
              color="brand-dark.500"
              fontSize="lg"
              fontWeight="large"
              fontFamily="Rubik"
            >
              {data?.patient?.name}
            </Text>
          </VStack>

          <VStack
            spacing="1"
            ml="10"
            pl="10"
            align="flex-start"
            borderLeft="1px solid #ABABAB"
          >
            <Text
              color="#A1A1A1"
              fontSize="xs"
              fontWeight="normal"
              fontFamily="Rubik"
            >
              IDADE
            </Text>
            <Text
              color="brand-dark.500"
              fontSize="lg"
              fontWeight="large"
              fontFamily="Rubik"
            >
              {data?.patient?.age}
            </Text>
          </VStack>
        </GridItem>

        <GridItem display="flex" justifyContent="flex-end" mr={5} colSpan={6}>
          <DrawerIaChat isFluctuant={false} onMessageCallback={() => {}} />
        </GridItem>
      </Grid>
    </Skeleton>
  );
}
