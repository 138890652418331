import {
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  Tooltip,
} from '@chakra-ui/react';
import {
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useState,
  useEffect,
} from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { BiVolumeFull, BiVolumeMute } from 'react-icons/bi';
import { MdOutlineInfo } from 'react-icons/md';
import SpeechToText from '@/pages/SpeechToText';

interface TagInputProps {
  name: string;
  label: string;
  values: string[];
  setValues: Dispatch<SetStateAction<string[]>>;
}

export function TagInput({ name, label, values, setValues }: TagInputProps) {
  const [inputValue, setInputValue] = useState('');
  const [listen, setListen] = useState(false);
  const [text, setText] = useState('');

  const ButtonsListen = () =>
    listen ? (
      <IconButton
        onClick={() => setListen(!listen)}
        variant="ghost"
        icon={<Icon as={BiVolumeMute} fontSize="18px" />}
        aria-label="Excluir todos os valores"
        colorScheme="whiteAlpha"
        color="text.darkened"
        position="absolute"
        right="5"
        zIndex="999"
      />
    ) : (
      <IconButton
        onClick={() => setListen(!listen)}
        variant="ghost"
        icon={<Icon as={BiVolumeFull} fontSize="18px" />}
        aria-label="Excluir todos os valores"
        colorScheme="whiteAlpha"
        color="text.darkened"
        position="absolute"
        right="5"
        zIndex="999"
      />
    );

  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      event.preventDefault();

      setValues(prevState => [...prevState, inputValue]);
      setInputValue('');
      setListen(false);
      setInputValue('');
    }
  }

  function handleRemove(value: string) {
    setValues(prevState => prevState.filter(v => v !== value));
  }

  function handleRemoveAll() {
    setValues([]);
  }

  useEffect(() => {
    setInputValue(inputValue.concat(` ${text}`));
  }, [text]);

  return (
    <FormControl>
      <SpeechToText setText={setText} setListen={setListen} listen={listen} />

      <Flex w="100%" align="center">
        <FormLabel
          htmlFor={name}
          mr="2px"
          fontSize="15px"
          fontWeight="500"
          color="#0006"
          fontFamily="Rubik"
          textTransform="uppercase"
        >
          {label}
        </FormLabel>
        <Tooltip
          hasArrow
          placement="top"
          label="Tecle ENTER para adicionar um novo valor"
          bg="brand-green.default"
          color="white"
        >
          <span>
            <Icon
              as={MdOutlineInfo}
              color="rgba(191, 191, 191, 0.7)"
              fontSize="18px"
            />
          </span>
        </Tooltip>
      </Flex>

      <Flex
        py="6px"
        pl="16px"
        minH="40px"
        border="1px solid #bfbfbf"
        borderRadius="4px"
        background="white"
        color="#0007"
        fontFamily="Rubik"
        textTransform="uppercase"
        align="center"
        wrap="wrap"
        gap="8px"
        position="relative"
        _hover={{
          borderColor: 'brand-green.default',
        }}
      >
        <Input
          variant="unstyled"
          value={inputValue}
          onChange={event => setInputValue(event.target.value)}
          onKeyDown={handleKeyDown}
          id={name}
          name={name}
          w="auto"
        />

        <IconButton
          onClick={handleRemoveAll}
          variant="ghost"
          icon={<Icon as={IoCloseOutline} fontSize="18px" />}
          aria-label="Excluir todos os valores"
          colorScheme="whiteAlpha"
          color="text.darkened"
          position="absolute"
          right="0"
        />
        <ButtonsListen />
      </Flex>
      {values.map(value => (
        <Tag
          key={value}
          borderRadius="full"
          variant="solid"
          bg="brand-green.darkened"
          margin="2"
          fontFamily="Rubik"
        >
          <TagLabel>{value}</TagLabel>
          <TagCloseButton onClick={() => handleRemove(value)} />
        </Tag>
      ))}
    </FormControl>
  );
}
